<template>
  <b-overlay
    :show="is_loading"
    spinner-variant="primary"
    variant="transparent"
    style="min-height: 50vh;"
  >
    <b-row class="d-flex">
      <b-col md="12" lg="12" cols="12">
        <b-card-header class="mb-3" style="border: none;">
          <b-card-title class="mb-0">Аналитика</b-card-title>
          <div class="d-flex" style="justify-content: center;">
            <b-button-group>
              <b-button
                @click="updateTab(0)"
                :variant="t === 0 ? 'primary' : 'light'"
                size="sm"
                >Весь период</b-button
              >
              <b-button
                @click="updateTab(1)"
                :variant="t === 1 ? 'primary' : 'light'"
                size="sm"
                >Сегодня</b-button
              >
              <b-button
                @click="updateTab(2)"
                :variant="t === 2 ? 'primary' : 'light'"
                size="sm"
                >Вчера</b-button
              >
              <b-button
                @click="updateTab(3)"
                :variant="t === 3 ? 'primary' : 'light'"
                size="sm"
                >Неделя</b-button
              >
              <b-button
                @click="updateTab(4)"
                :variant="t === 4 ? 'primary' : 'light'"
                size="sm"
                >Месяц</b-button
              >
              <b-button
                :variant="t === 5 ? 'primary' : 'light'"
                size="sm"
              >
                <b-form-group class="pt-0 pb-0" style="margin-bottom: 4px; color: #2a2e30">
                  <div
                    class="d-flex justify-content-between mt-auto mb-auto"
                    style="height: 16px;"
                  >
                    <div class="mt-auto mb-auto" style="flex-grow: 1">
                      <date-range-picker
                        ref="picker"
                        :locale-data="calendar_locale"
                        :max-date="maxPickerDate"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="true"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="false"
                        :linkedCalendars="false"
                        :ranges="false"
                        v-model="custom_period"
                        @update="updateTab(5)"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px; color: rgb(42, 46, 48);"
                        >
                          <span v-if="picker.startDate" :style="t === 5 ? 'color: #FFF' : ''"
                            >{{ picker.startDate | date }} -
                            {{ picker.endDate | date }}</span
                          >
                          <span v-else class="font-weight-normal">
                            <feather-icon
                              icon="CalendarIcon"
                              class="mt-auto mb-auto"
                              style="margin-right: 4px"
                              size="18"
                            />Период</span
                          >
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </b-form-group></b-button
              >
            </b-button-group>
          </div>
        </b-card-header>
      </b-col>
    </b-row>

      <b-row class="d-flex">
      <b-col md="12" lg="12" cols="12">
        <list-users-view :registers="statistics.registrations" :clients="statistics.clients" />
      </b-col>
    </b-row>

    <b-row class="d-flex">
      <b-col  md='9' lg='9' cols='12'>
         <b-row>
            <b-col md="6" lg="4" cols="12">
              <small-card-view :title="'Всего купили'" :ths="statistics.cloud.ths + statistics.miners.ths" :investments="statistics.cloud.investments + statistics.miners.investments" :contracts="statistics.cloud.count + statistics.miners.count" :styleComponent="{backgroundColor: '#8f8f8f14'}" />
            </b-col>
            
            <b-col md="6" lg="4" cols="12">
              <small-card-view :title="'Аренда'" :ths="statistics.cloud.ths" :investments="statistics.cloud.investments" :contracts="statistics.cloud.count" />
            </b-col>

            <b-col md="6" lg="4" cols="12">
              <small-card-view :title="'Майнеры'" :ths="statistics.miners.ths" :investments="statistics.miners.investments" :contracts="statistics.miners.count"/>
            </b-col>

            <b-col md="6" lg="12" cols="12">
              <b-card no-body>
                  <b-card-header>
                    <b-card-title>
                      EMCD воркеры
                    </b-card-title>
                  </b-card-header>
                  <b-skeleton-table v-if='is_loading_workers && emcdWorkers.length > 0' animation="fade" :columns='5' :rows='5'>
                  </b-skeleton-table>
            
                  <b-table-simple v-else class="font-small-3 text-black" responsive>
                    <b-thead>
                      <b-tr>
                        <b-td>Имя<br>воркера</b-td>
                        <b-td>Текущий</b-td>
                        <b-td>Ср. хешрейт/1ч</b-td>
                        <b-td>Ср. хешрейт/24ч</b-td>
                        <b-td>Reject rate/24ч</b-td>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-black font-weight-bolder">Общий хешрейт ({{emcdWorkers.length}})</b-td>
                        <b-td class="text-black">{{ Math.round10(emcdTotalHashrate.hashrate / $user.contracts.values.H, -2) }} TH/s</b-td>
                        <b-td class="text-black">{{ Math.round10(emcdTotalHashrate.hashrate1h / $user.contracts.values.H, -2) }} TH/s</b-td>
                        <b-td class="text-black">{{ Math.round10(emcdTotalHashrate.hashrate24h / $user.contracts.values.H, -2) }} TH/s</b-td>
                        <b-td class="text-black"></b-td>
                      </b-tr>
                      <b-tr v-for="(item, index) in emcdWorkers" :key="index">
                        <b-td class="d-flex flex-row text-black font-weight-bolder" style="align-items: center;">
                          <div v-if="item.active == 1" class="ball-green"></div>
                          <div v-else class="ball-gray"></div>
                            {{ item.worker }}
                        </b-td>
                        <b-td class="text-black">{{  Math.round10(item.hashrate / $user.contracts.values.H, -2) }} TH/s</b-td>
                        <b-td class="text-black">{{  Math.round10(item.hashrate1h / $user.contracts.values.H, -2) }} TH/s</b-td>
                        <b-td class="text-black">{{  Math.round10(item.hashrate24h / $user.contracts.values.H, -2) }} TH/s</b-td>
                        <b-td class="text-black">{{ Math.round10(item.reject, -2) }} %</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-card>
              </b-col>
           </b-row>
         </b-col>

            <b-col md="6" lg="3" cols="12">
              <miners-list-view :miners="statistics.miners.list" />
            </b-col>
          </b-row>
      
    <!-- </b-row> -->

  

    <!-- <b-row class="d-flex">
      <b-col cols="12">
        <graph-view />
      </b-col>
    </b-row> -->
  </b-overlay>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import SmallCardView from "./widgets/SmallCardView"
import MinersListView from "./widgets/MinersListView"
import ListUsersView from "./widgets/ListUsersView"

import Blockchain from "@/modules/blockchain"

// import GraphView from "./widgets/GraphView"

export default {
  data() {
    return {
      Blockchain,

      t: 4,
      is_loading: false,
      is_loading_workers: true,

      calendar_locale: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "Применить",
        cancelLabel: "Отмена",
        weekLabel: "Н",
        customRangeLabel: "Период",
        daysOfWeek: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        monthNames: [
          "Янв",
          "Фев",
          "Мар",
          "Апр",
          "Май",
          "Июн",
          "Июл",
          "Авн",
          "Сен",
          "Окт",
          "Нов",
          "Дек",
        ],
        firstDay: 0,
      },

      maxPickerDate: new Date(),
      custom_period: {
        startDate: null,
        endDate: null,
      },

      period: '1month',
      statistics: {
        clients: 0,
        registrations: 0,
        cloud: {
          ths: 0,
          investments: 0,
          count: 0
        },
        miners: {
          ths: 0,
          investments: 0,
          count: 0,
          list: []
        }
      },
    };
  },
  methods: {
    get() {
        this.is_loading = true;

        let period = null;
                
        if( this.custom_period.startDate && this.custom_period.endDate && this.t === 5 ) {
          period = {};
          period['start'] = this.custom_period.startDate;
          period['end'] = this.custom_period.endDate;
        } else {
          period = this.period
        }

        this.$request.get("analytics/get", {
          period: period
        } ).then( rsp => {
            this.statistics = rsp;
            this.is_loading = false;
        });
    },

    updateTab(tab) {
      this.t = tab;

      switch(tab) {
        case 0:
          this.period = 'all'
          break;
        case 1:
          this.period = 'today'
          break;
        case 2:
          this.period = 'yesterday'
          break;
        case 3:
          this.period = '1week'
          break;
        case 4:
          this.period = '1month'
          break;
      }

      this.get()
    }
  },
  filters: {
    date(val) {
      return val
        ? new Intl.DateTimeFormat("ru", {
            day: "numeric",
            year: "2-digit",
            month: "numeric",
          }).format(val)
        : "";
    },
  },
  computed: {
    emcdTotalHashrate() {
      return Blockchain.emcd.workers.total_hashrate;
    },
    emcdTotalCount() {
      return Blockchain.emcd.workers.total_count;
    },
    emcdWorkers() {
      return Blockchain.emcd.workers.details.sort((prev, next) => next.hashrate - prev.hashrate);
    },
    emcdWorkersDead() {
      return Blockchain.emcd.workers.detailsDead;
    }
  },
  components: {
    DateRangePicker,
    SmallCardView,
    MinersListView,
    ListUsersView,
    // GraphView
  },
  watch: {
    "t": function() {
      if (this.t !== 5) {
        this.custom_period= {
          startDate: null,
          endDate: null
        }
      }
    }
  },
  mounted() {
    this.get();

    this.$blockchain.emcd.get(() => {this.is_loading_workers = false});
  },
};
</script>

<style lang="scss" scoped>
.vue-daterange-picker {
  flex-grow: 1;
}
.btn-light {
  background-color: #f6f6f64f;
}
.btn-light:not(:disabled):not(.disabled):active {
  background-color: transparent !important;
}
.ball-green {
  background-color: #5dcd5a;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 100px;
}
.ball-gray {
  background-color: #bdbdbd;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 100px;
}
</style>
