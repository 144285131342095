<template>
    <b-card class="d-flex" no-body style="padding: 24px; flex-direction: row;">
        <div style="width: 50%;">
            <!-- border-right: 1px solid #ebe9f1 -->
            <b-card-title>
                Регистраций на платформе
            </b-card-title>
            <b-card-body class="d-flex" style="padding: 0; flex-direction: column;">
                <span style="font-size: 20px">{{registers}}</span>
            </b-card-body>
        </div>
        <div style="width: 50%; padding-left: 40px;">
            <b-card-title>
                Клиентов
            </b-card-title>
            <b-card-body class="d-flex" style="padding: 0; flex-direction: column;">
                <span style="font-size: 20px">{{clients}}</span>
            </b-card-body>
        </div>
  </b-card>
</template>

<script>
    
    export default {
        data() {
            return {
            }
        },
        props: {
            registers: {
                type: Number,
                default: 0
            },
            clients: {
                type: Number,
                default: 0
            }
        },
        methods: {
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>