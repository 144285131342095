<template>
    <b-card no-body>
        <b-card-header>
            <b-card-title>
               Список майнеров
            </b-card-title>
        </b-card-header>
        <b-list-group v-if="miners.length > 0" flush class="mt-0 pt-0">
            <b-list-group-item v-for="(item, index) in miners" :key="item.id" class="d-flex align-items-center">
                <span style="margin-right: 4px"> {{index + 1}}.</span>

                <div class="ml-1 flex-grow-1">
                    <div class="d-flex justify-content-between">
                        <div class="font-small-2">
                            <span class="transaction-title cursor-pointer font-weight-bolder">
                               {{ item.machine.name }}
                            </span>
                            <div >
                               {{ item.machine.serial_number }}
                            </div>
                        </div>
                    </div>
                </div>     
            </b-list-group-item>
        </b-list-group>
        <b-card-body v-else class="text-center">
            <feather-icon icon="InfoIcon" size="52" class="text-danger" />
            <b-card-text class="text-muted mb-0 mt-1">
               Данные отсутствуют
            </b-card-text>
        </b-card-body>
    </b-card>
</template>

<script>
    
    import Utils from "@/modules/utils/"

    export default {

        data() {
            return {
                Utils
            }
        },
        props: {
            miners: {
                type: Array, 
                default: []
            }
        },
        methods: {
            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>