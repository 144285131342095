<template>
  <b-card class="p-relative" no-body style="padding: 24px;" :style="styleComponent ? styleComponent : ''">
    <b-card-title>
      {{title}}
    </b-card-title>
    <b-card-body class="d-flex" style="padding: 0; flex-direction: column;">
      <div class="mb-1 d-flex" style="justify-content: space-between;">
        <span class="text-muted">Сумма</span>
        <span style="text-align: end">{{investments.formatMoney(2,"", " ")}} $<br> ≈ {{(investments / $blockchain.tickers.values.USD.last).formatMoney(8,"", " ")}} BTC</span>
      </div>
      <div class="mb-1 d-flex" style="justify-content: space-between;">
        <span class="text-muted">Хешрейт</span>
        <span>{{ths.formatMoney(2,"", " ")}} TH/s </span>
      </div>
      <hr class="m-0">
      <div class="c-mt d-flex" style="justify-content: space-between;">
        <span class="text-muted">Контракты</span>
        <span>{{contracts}}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    investments: {
      type: Number,
      default: 0
    },
    ths: {
      type: Number,
      default: 0
    },
    contracts: {
      type: Number,
      default: null
    },
    styleComponent: {
      type: Object,
      default: null
    }
  }
  
};
</script>

<style lang="scss" scoped>
  // hr {
  //   width: 70%;
  // }
  .c-mt {
    margin-top: 8px;
  }
</style>
